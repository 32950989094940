.row .col-md-1,
.row .col-md-2,
.row .col-md-3,
.row .col-md-4,
.row .col-md-5,
.row .col-md-6,
.row .col-md-7,
.row .col-md-8,
.row .col-md-9,
.row .col-md-10,
.row .col-md-11,
.row .col-md-12 {
  z-index: unset;
}
/* .personal_info.member_area img */
.vid-wrapper.px-3 {
  padding: 0 15px !important;
}

#video-call-container .video-dishplay,
#video-call-container .video-dishplay-caller {
  border: 1px solid #8f8f8f;
}
#video-call-container .video-dishplay,
#video-call-container .video-dishplay-caller {
  border-radius: unset;
}
#video-call-container .video-dishplay video,
#video-call-container .video-dishplay-caller video {
  object-fit: cover;
  object-position: center;
  border-radius: unset !important;
}

#video-call-container img {
  width: unset;
  height: unset;
  /* border-radius: 100px; */
  border: unset;
  padding: unset;
  /* object-fit: cover; */
  /* border-radius: unset; */
  border-radius: 10px;
}
.video-dishplay video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

#video-call-container {
  display: inline-block;
  z-index: 1000 !important;
}

#video-call-container .video-dishplay {
  max-width: 600px;
  margin: 0 auto;
}

.w100 {
  width: 100%;
}
@media (min-width: 990px) {
  .modal .modal-dialog.minwidth800 {
    width: 700px;
  }
}
#video-call-container .modal-content.border-none {
  border: unset;
}
#video-call-container .modal-content.w-resize {
  max-width: 400px;
}
.d-none {
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}
.vid-100 video {
  width: 100%;
}
.md-z-index {
  z-index: 10000 !important;
}
.call-items img {
  width: 50px !important;
  height: 50px !important;
}
.call-items a {
  border: none !important;
}
.modal-content {
  background-color: #bfbfbf;
  background-color: #fafafa;
  border: 10px solid #00b050;
  border-radius: 2px;
}

/* Custom Modal */
.custom-modal {
  /* display: none; */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2 !important;
}

.custom-modal-content {
  background-color: #fff;
  margin: 15% auto;
  padding: 20px;
  /* border: 1px solid #888; */
  width: 50%;
  text-align: center;
}

.custom-modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.custom-modal-close:hover,
.custom-modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.btn-outline-info {
  background-color: transparent;
  border: 1px solid #46b8da;
  color: #46b8da;
}

.btn-outline-primary {
  background-color: transparent;
  border: 1px solid #337ab7;
  color: #337ab7;
}
.btn-outline-success {
  background-color: transparent;
  border: 1px solid green;
  color: green;
}

.btn-outline-primary:hover {
  background-color: #204d74;
  color: white;
}
.btn-outline-info:hover {
  background-color: #46b8da;
  color: white;
}
.btn-success {
  background-color: #55ce63;
  color: white;
}

.btn-outline-success:hover {
  background-color: #55ce63;
  color: white;
}

.spin-loader {
  display: inline-block;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db00;
  width: 15px;
  height: 15px;
  -webkit-animation: spinn 700ms linear infinite; /* Safari */
  animation: spinn 700ms linear infinite;
}
.spin-loader.spin-info {
  display: inline-block;
  border: 3px solid #3498db;
  border-top: 3px solid #f3f3f3;
}
/* Safari */
@-webkit-keyframes spinn {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.d-flex {
  display: flex;
  display: -webkit-flex;
}
.items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}

.position-relative,
.p-relative {
  position: relative;
}
.position-absolute,
.p-absolute {
  position: absolute;
}
.blurCheckboxWrapper {
  right: -10px;
  top: 15px;
  text-wrap: nowrap;
}

.d-inline-block {
  display: inline-block;
}

.delteVTBWrapper {
  right: 0;
  top: 0;
  z-index: 10;
  width: 30px !important;
  height: 30px !important;
  padding: unset !important;
  border-radius: 300px !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex !important;
  display: -webkit-flex !important;
}

.d-flex-wrap {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
}
.modal-content {
  cursor: move;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
