#video-call-container .modal-content {
  background-color: #eaeaea;
  border: 10px solid #00b050;
  border-radius: 0px;
}

#video-call-container .video-dishplay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#video-call-container .video-dishplay {
  width: 100%;
  /* height: 450px; */
  height: 340px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}
#video-call-container .video-dishplay-caller {
  /* width: 100%; */
  height: 130px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  width: 200px;
  margin: auto;
  margin-top: 20px;
}
#video-call-container .call-items {
  display: flex;
  justify-content: center;
  margin: 30px;
}

#video-call-container .call-item {
  margin: 0 10px;
}

#video-call-container .call-start {
  background: #55ce63;
  color: #000;
  line-height: 0;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  padding: 19px 7px;
  display: inline-block;
  border: 1px solid #000;
  font-weight: 600;
  width: 150px;

  border-radius: 0;
  text-transform: uppercase;
}

#video-call-container .call-start:hover {
  color: #fff;
}
#video-call-container .call-box .call-wrapper {
  text-align: center;
}
#video-call-container .call-box .call-wrapper .call-avatar {
  margin-bottom: 0px;
  cursor: pointer;
}
#video-call-container .call-box .call-avatar {
  border-radius: 100%;
  position: relative;
  border: 4px solid #fff;
  height: 15rem;
  width: 15rem;
  overflow: hidden;
  margin: auto;
}
#video-call-container .call-box .call-avatar img {
  width: 100%;
}
#video-call-container .border-0 {
  border: 0;
}
#video-call-container .call-box .call-wrapper .call-items .call-item.call-end {
  background: #f06060;
}
#video-call-container .call-box .fa.fa-phone.phone_icon {
  rotate: 136deg;
}

#video-call-container .call-user h4,
#video-call-container .calling-form h4 {
  color: #000;
  font-weight: 600;
  font-size: 25px;
  padding-bottom: 15px;
}

#video-call-container .calling-form h4 {
  padding-bottom: 15px;
}
#video-call-container .chat_cal {
  font-size: 18px;
  color: #000 !important;
}

#video-call-container .call-box .call-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#video-call-container .modal {
  text-align: center;
  padding: 0 !important;
}

#video-call-container .modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

#video-call-container .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

#video-call-container .arrow {
  position: absolute;
  top: 50%;
  left: 49%;
  transform: translate(-50%, -50%);
  transform: rotate(-90deg);
  cursor: pointer;
}

#video-call-container .arrow span {
  display: block;
  width: 1.5vw;
  height: 1.5vw;
  border-bottom: 5px solid #00b050;
  border-right: 5px solid #00b050;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

#video-call-container .arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

#video-call-container .arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

#video-call-container .call-items a {
  margin: 1px 12px;
  color: #000;
  font-size: 17px;
  text-decoration: none !important;
}
#video-call-container .call-items a img {
  margin-bottom: 10px;
}

#video-call-container .viewuser {
  position: absolute;
  top: 13px;
  right: 23px;
  width: 150px !important;
  height: 150px !important;
}

#video-call-container .modal-body.voice_body {
  padding-top: 0;
}

#video-call-container
  .modal-dialog.modal-dialog-centered.declined-screen
  .modal-content {
  height: 400px;
}

#video-call-container .declined-screen .call-items {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
  margin: auto;
  height: 80px;
}

#video-call-container .declined-screen .CallDeclined {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  margin: auto;
  height: 80px;
  font-weight: 600;
  color: #000;
}

#video-call-container .CallDeclined {
  font-size: 18px !important;
}

@media (min-width: 992px) {
  .modal-lg {
    width: 800px;
  }

  .pe-lg-7 {
    padding-right: 7px;
  }
  .ps-lg-7 {
    padding-left: 7px;
  }
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

#video-call-container .icon-video {
  background: #00b050;

  border-radius: 2px;
  color: #fff;
  display: inline-block;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 7px 15px;
  margin: auto;
}

#video-call-container .icon-video.icon-rounded {
  width: 50px;
  height: 50px;
  font-size: 24px;
  display: flex;
  margin-bottom: 4px;
  border-radius: 100%;
}

#video-call-container .icon-video.bg-danger {
  background: #e04f5f;
}
#video-call-container .icon-video.bg-info {
  background: #2d8cff;
}

/* **************** New Csss only use this css start ************** */

.virtualimg a {
  height: 120px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: inline-block;
  border-radius: 2px;
}

.virtualimg a img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 2px !important;
}

.virtualimg {
  /* padding: 10px; */
  /* margin-bottom: 10px; */
}
.gallery-img .col4 {
  padding: 10px;
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

.video-dishplay-incoming-box {
  width: 100%;
  height: 160px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  width: 220px;
  margin: auto;
  margin-top: 20px;
}

.video-dishplay-incoming-box img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#myModanew .video-dishplay {
  width: 100%;
  height: 350px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}

/**************** New Csss only use this css end**************/
